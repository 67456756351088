// Customizable Area Start
import { RouteComponentProps } from "react-router-dom";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { NewPostCreationSteps, CaptionViews } from "./enums";
import { File, Poll } from "./types";
import { getToken } from "../../../components/src/AuthService";

export const configJSON = require("./config");

export type Props = RouteComponentProps & {
  id?: string;
  classes?: any;
  navigation: any;
};

interface S {
  currentStep: NewPostCreationSteps;
  files: File[];
  cover: string;
  captionView: CaptionViews;
  caption: string;
  bodyText: string;
  poll: Poll;
  isCommentsAllowed: boolean;
  error: string;
}

interface SS {
  navigation: any;
}

export default class NewPostController extends BlockComponent<Props, S, SS> {
  sendPostApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      currentStep: NewPostCreationSteps.SELECT_FILE,
      files: [],
      cover: "",
      captionView: CaptionViews.NONE,
      caption: "",
      bodyText: "",
      poll: {
        question: "Ask a question",
        answer1: "Answer 1",
        answer2: "Answer 2",
      },
      isCommentsAllowed: true,
      error: "",
    };
  }


  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): void {
    if (prevState.files !== this.state.files && this.state.files.length) {
      this.setStep(NewPostCreationSteps.ADD_CAPTION);
    }
  }

  setStep = (step: NewPostCreationSteps) => {
    this.setState({ currentStep: step });
  };

  closeModal = () => {
    this.props.history.goBack();
  };

  setFiles = (files: File[]) => {
    this.setState({
      files,
    });
  };

  setPollQuestion = (question: string) => {
    this.setState((prevState) => {
      return { poll: { ...prevState.poll, question } };
    });
  };

  setPollAnswer1 = (answer: string) => {
    this.setState((prevState) => {
      return { poll: { ...prevState.poll, answer1: answer } };
    });
  };

  setPollAnswer2 = (answer: string) => {
    this.setState((prevState) => {
      return { poll: { ...prevState.poll, answer2: answer } };
    });
  };

  setCaptionView = (view: CaptionViews) => {
    this.setState(
      (prevState) => {
        if (prevState.captionView === view) {
          return { captionView: CaptionViews.NONE };
        }
  
        return {
          captionView: view,
        };
      },
      () => {
        const captionField = document.getElementById("captionInput");
        if (view === CaptionViews.CAPTION && captionField) {
          captionField.focus();
        }
      }
    );
  };  

  setCaption = (caption: string) => {
    this.setState({
      caption,
    });
  };

  setCover = (coverURL: string) => {
    this.setState({
      cover: coverURL,
    });
  };

  allowComments = () => {
    this.setState((prevState) => {
      return {
        isCommentsAllowed: !prevState.isCommentsAllowed,
      };
    });
  };

  sendPost = () => {
    const formData = new FormData();

    formData.append("post[body]", this.state.bodyText || "New post");

    this.state.files.forEach((file) => {
      formData.append("post[files][]", (file as unknown) as Blob);
    });

    if (this.state.poll) {
      formData.append(
        "post[post_poll_attributes][question]",
        this.state.poll.question
      );
      formData.append(
        "post[post_poll_attributes][post_poll_options_attributes][][name]",
        this.state.poll.answer1
      );
      formData.append(
        "post[post_poll_attributes][post_poll_options_attributes][][name]",
        this.state.poll.answer2
      );
    }

    if(this.state.caption) {
      formData.append("post[description]", this.state.caption);
    }

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.sendPostApiCallId) {
      this.handleSendPostAPIResponse(message);
    }
    
  }

  handleSendPostAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.data?.attributes?.errors?.body.length) {
      this.setState({error: responseJson.data.attributes.errors.body[0]})

      return;
    }

    this.closeModal();
  };


}
// Customizable Area End
