// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Avatar,
  IconButton,
  Paper,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { createStyles, styled, Theme, withStyles } from "@material-ui/core/styles";
import { IconAvtar, womanImage } from "../../contactlist/src/assets";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { MoreHorizRounded } from "@material-ui/icons";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MuiAlert from "@material-ui/lab/Alert";
import OpenNotificationController, {
  Props,
  CommentOrderEnum
} from "./OpenNotificationController.web";
import { everyBrandLogo } from "../../postcreation/src/assets";
import { RedLikeIcon, sendIcon, whiteCommentIcon, UploadIcon } from "./assets";
import MediaComponent from "../../../components/src/MediaComponent.web";
import DescriptionComponent from "../../../components/src/ReadMoreDescription.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import clsx from "clsx"
import CommentImage from "../../../components/src/CommentImage.web";
const helper = require("../../../components/src/helper.js")

export default class OpenNotification extends OpenNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      isLoading,
      postData,
      isShowAlert,
      showSuccess,
      repliesOpenCommentId,
      isAddingComment,
      commentsOrder,
      isSortingComments,
      postComments,
      infoType,
      comment,
      commentImage
    } = this.state

    if (isLoading || !postData) {
      return (
        <CustomLoader loaderSize={28} wrapperHeight="70vh" isLoading={isLoading} />
      )
    }

    return (
      <Container
        disableGutters
        maxWidth={false}
        className={classes.mainNotificationContainer}
      >
        <Snackbar
          open={isShowAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert elevation={6} variant="filled" severity={infoType}>
            {showSuccess}
          </MuiAlert>
        </Snackbar>
        <Box className={classes.postHeadingWrapper}>
          <IconButton
            data-test-id="backIcon"
            onClick={() => this.handleRedirection("Notifications")}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <div className={classes.postAvatar}>
            <Avatar className={classes.activeUserAvatar}>
              <img
                src={this.ternary(
                  postData.attributes.account_profile,
                  postData.attributes.account_profile.url,
                  IconAvtar
                )}
                alt="User Profile"
                className={classes.fallbackAvatar}
              />
            </Avatar>
            <div>
              <Typography className={classes.name}>
                {`${postData.attributes.account.first_name} ${postData.attributes.account.last_name}`}
              </Typography>
              <Typography className={classes.contactInfoNumber}>
                {postData.attributes.created_at}
              </Typography>
            </div>
          </div>
        </Box>
        <Box className={classes.postWrapper}>
          <Box className={classes.previewContainer}>
            <Box className={classes.imagePreviewContainer}>
              <MediaComponent
                url={Array.isArray(postData.attributes.files) ? postData.attributes.files[0].link : womanImage}
              />
            </Box>
            <Box className={classes.previewTopOverlay}>
              <Box className={classes.overlayBottom}>
                <Box className={classes.postInfoContainer}>
                  <Box className={classes.postTitleAndCaption}>
                    <Typography className={classes.postOverlayTitle}>
                      Necronomicon ART
                    </Typography>
                    <Typography className={classes.postOverlayTitle}>
                      {postData.attributes.account.first_name +
                        " " +
                        postData.attributes.account.last_name}
                    </Typography>
                    <DescriptionComponent
                      data-test-id="post-description"
                      description={postData.attributes.description}
                    />
                  </Box>
                  <Box className={classes.postSocialStats}>
                    <Box className={classes.avatarContainer}>
                      <img
                        src={this.ternary(
                          postData.attributes.account_profile,
                          postData.attributes.account_profile.url,
                          IconAvtar
                        )}
                        alt="avatar"
                        className={classes.avatar}
                      />
                    </Box>
                    <Box className={classes.socialStatsContainer}>
                      <IconButton
                        data-test-id="post-like-button"
                        size="small"
                        onClick={this.handlePostLikeUnlike}
                      >
                        {this.ternary(
                          postData.attributes.is_liked,
                          <FavoriteRoundedIcon className={classes.postLikedImage} />,
                          <FavoriteBorderRoundedIcon className={classes.likesIcon} />
                        )}
                      </IconButton>
                      <Typography className={classes.countsFonts}>
                        {postData.attributes.likes_count}
                      </Typography>
                    </Box>
                    <Box className={classes.socialStatsContainer}>
                      <img
                        src={whiteCommentIcon}
                        alt="comments"
                        className={classes.commentsIcon}
                      />
                      <Typography className={classes.countsFonts}>
                        {postData.attributes.comments_count}
                      </Typography>
                    </Box>
                    <Box className={classes.socialStatsContainer}>
                      <MoreHorizRounded />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.everybrandLogoContainer}>
                  <Typography className={classes.everybrandText}>
                    Powered by
                  </Typography>
                  <img src={everyBrandLogo} alt="Everybrand" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.commentsContainer}>
          <div data-test-id="all-comments" title="Show all comments" onClick={this.showAllComments}>
            <Typography>All comments</Typography>
          </div>
          <IconButton
            size="small"
            title="Sort"
            data-test-id="sort-button"
            disabled={isSortingComments}
            className={clsx({
              [classes.expandedArrow]: commentsOrder === CommentOrderEnum.DESCENDING,
            })}
            onClick={this.toggleCommentSortChange}
          >
            <ExpandMoreRoundedIcon />
          </IconButton>
        </Box>
        <Box className={classes.commentListWrapper}>
          <CustomLoader
            wrapperHeight="70vh"
            loaderSize={22}
            isLoading={isSortingComments}
          >
            <div
              ref={this.commentsWrapperRef}
              className={classes.commentsScrollableWrapper}
            >
              {postComments.length > 0 &&
                postComments.map((commentDetails) => {
                  const {
                    id,
                    attributes: {
                      already_liked,
                      created_at,
                      account,
                      comment,
                      replies,
                      files
                    },
                  } = commentDetails;
                  const {
                    data: {
                      attributes: { first_name, last_name, profile_pic },
                    },
                  } = account;
                  const isRepliesExist = replies.data.length > 0;
                  const isRepliesExpanded = repliesOpenCommentId === id;
      
                  return (
                    <ReceiverMessageContainer key={id} id={`comment-${id}`}>
                      <Box id="commentAvtarBox">
                        <Avatar
                          className={classes.activeUserAvatar}
                          data-test-id="commentAvtarBox"
                        >
                          <img
                            src={this.orCondition(profile_pic?.url, IconAvtar)}
                            alt="profilepic"
                            className={classes.fallbackAvatar}
                          />
                        </Avatar>
                      </Box>
                      <Box flex={1} maxWidth="650px">
                        <MessageBubble highlight={this.isToHighlight(id)}>
                          <div className={classes.messageDetailsWrapper}>
                            <div className="commenterNAme">
                              <Typography className={classes.commentUsername}>
                                {`${first_name} ${last_name}`}
                              </Typography>
                            </div>
                            <div className="commenterTime">
                              <Typography className={classes.commentText}>
                                {helper.timeSince(created_at)}
                              </Typography>
                            </div>
                          </div>
                          <Typography data-test-id="comment-text">{comment}</Typography>
                          <CommentImage files={files} />
                        </MessageBubble>
                        <Box className={classes.commentActionsWrapper}>
                          <button
                            data-test-id="comment-reply-button"
                            className={classes.replyButton}
                            onClick={() => this.handleReplyClick(commentDetails)}
                          >
                            Reply
                          </button>
                          {isRepliesExist && (
                            <Box className={classes.replyExpandWrapper}>
                              <Typography>
                                {this.getReplyText(replies.data.length)}
                              </Typography>
                              <IconButton
                                data-test-id="reply-toggle"
                                size="small"
                                className={clsx({
                                  [classes.expandedArrow]: isRepliesExpanded,
                                })}
                                onClick={() => this.toggleReplies(id)}
                              >
                                <ExpandMoreRoundedIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        {isRepliesExpanded && (
                          <Box>
                            {replies.data.map((replyData) => {
                              const {
                                id,
                                attributes: {
                                  account,
                                  already_liked,
                                  created_at,
                                  comment,
                                  files
                                },
                              } = replyData;
                              const {
                                data: {
                                  attributes: { first_name, last_name, profile_pic },
                                },
                              } = account;
      
                              return (
                                <ReceiverMessageContainer key={id} id={`Reply ${id}`}>
                                  <Box id="commentAvtarBox">
                                    <Avatar
                                      className={classes.activeUserAvatar}
                                      data-test-id="commentAvtarBox"
                                    >
                                      <img
                                        src={this.orCondition(
                                          profile_pic?.url,
                                          IconAvtar
                                        )}
                                        alt="profilepic"
                                        className={classes.fallbackAvatar}
                                      />
                                    </Avatar>
                                  </Box>
                                  <Box flex={1} maxWidth="650px">
                                    <MessageBubble highlight={this.isToHighlight(id)}>
                                      <div className={classes.messageDetailsWrapper}>
                                        <div className="commenterNAme">
                                          <Typography
                                            className={classes.commentUsername}
                                          >
                                            {`${first_name} ${last_name}`}
                                          </Typography>
                                        </div>
                                        <div className="commenterTime">
                                          <Typography className={classes.commentText}>
                                            {helper.timeSince(created_at)}
                                          </Typography>
                                        </div>
                                      </div>
                                      <Typography data-test-id="comment-reply">
                                        {comment}
                                      </Typography>
                                      <CommentImage files={files} />
                                    </MessageBubble>
                                    <Box className={classes.commentActionsWrapper}>
                                      <button
                                        data-test-id="reply-reply-button"
                                        className={classes.replyButton}
                                        onClick={() =>
                                          this.handleReplyClick(
                                            replyData,
                                            commentDetails.id
                                          )
                                        }
                                      >
                                        Reply
                                      </button>
                                    </Box>
                                  </Box>
                                  <IconButton
                                    className={classes.likeButton}
                                    data-test-id="comment-reply-like-toggle"
                                    onClick={() =>
                                      this.handleCommentReplyLikeUnlike(
                                        commentDetails.id,
                                        id,
                                        already_liked
                                      )
                                    }
                                  >
                                    {this.ternary(
                                      already_liked,
                                      <img
                                        src={RedLikeIcon}
                                        alt="Undo like"
                                        className={classes.likedImage}
                                      />,
                                      <FavoriteBorderIcon />
                                    )}
                                  </IconButton>
                                </ReceiverMessageContainer>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                      <IconButton
                        data-test-id="comment-like-toggle"
                        className={classes.likeButton}
                        onClick={() =>
                          this.handleCommentLikeUnlike(id, already_liked)
                        }
                      >
                        {already_liked ? (
                          <img
                            src={RedLikeIcon}
                            alt="Undo like"
                            className={classes.likedImage}
                          />
                        ) : (
                          <FavoriteBorderIcon />
                        )}
                      </IconButton>
                    </ReceiverMessageContainer>
                  );
                })}
            </div>
          </CustomLoader>
          <Box className={classes.addCommentWrapper}>
            <Box className={classes.addCommentInnerWrapper}>
              <Box className={classes.iconButtonWrapper}>
                <Avatar className={classes.activeUserAvatar} data-test-id="commentAvtarBox">
                  <img
                    src={this.ternary(
                      postData.attributes.account_profile,
                      postData.attributes.account_profile.url,
                      IconAvtar
                    )}
                    alt="profilepic"
                    className={classes.fallbackAvatar}
                  />
                </Avatar>
              </Box>
              <Box flex={1}>
                {commentImage && (
                  <Box margin={1}>
                    <Box className={classes.commentImagePreviewWrapper}>
                      <IconButton
                        size="small"
                        data-test-id="image-comment-remove"
                        onClick={this.handleImageCommentRemove}
                      >
                        <CloseRoundedIcon fontSize="small" />
                      </IconButton>
                      <img
                        src={URL.createObjectURL(commentImage)}
                        alt="Image"
                        data-test-id="comment-image-selected"
                        className={classes.commentImagePreview}
                      />
                    </Box>
                  </Box>
                )}
                <TextField
                  data-test-id="innerReplyCommentsField"
                  placeholder={this.getFieldPlaceholder()}
                  variant="outlined"
                  inputRef={this.commentFieldRef}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                  fullWidth
                  multiline
                  maxRows={4}
                  onBlur={this.handleCommentFieldBlur}
                  value={this.state.comment}
                  name="replyComment"
                  InputProps={{
                    classes: {
                      root: classes.postReplyField,
                      input: classes.postReplyInput,
                    },
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      this.handlePostComment();
                    }
                  }}
                />
              </Box>
              <IconButton
                size="small"
                className={classes.iconButtonWrapper}
                data-test-id="comment-image-button"
                onClick={this.handleImageCommentClick}
              >
                <img src={UploadIcon} alt="Image upload" />
              </IconButton>
              <input
                hidden
                type="file"
                data-test-id="comment-image-input"
                accept=".jpeg, .jpg, .png"
                ref={this.imageCommentFieldRef}
                onChange={this.handleImageCommentUpload}
              />
              <IconButton
                size="small"
                className={classes.iconButtonWrapper}
                disabled={isAddingComment || !comment}
                data-test-id="postCommentApiCall"
                onClick={this.handlePostComment}
              >
                <img src={sendIcon} alt="Send" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Container>    
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ReceiverMessageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  marginRight: "auto",
  gap: "8px",
  marginTop: "10px"
});

const MessageBubble = styled(Paper)(({ highlight }: { highlight?: boolean }) => ({
  padding: "12px 16px",
  boxShadow: "none",
  borderRadius: "0px 16px 16px 16px",
  backgroundColor: highlight ? "#fff2d2" : "#F1F5F9",
  "& > div": {
    display: "flex",
    gap: "4px",
    alignItems: "baseline",
    justifyContent: "space-between"
  }
}));


export const styles: any = (theme: Theme) =>
  createStyles({
    previewTopOverlay: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "16px",
      paddingTop: "10px",
      borderRadius: "8px",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.45))"
    },
    overlayBottom: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    postInfoContainer: {
      display: "flex",
      gap: "15px",
      padding: "0px 25px 10px",
      justifyContent: "space-between",
    },
    postTitleAndCaption: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      justifyContent: "flex-end",
    },

    socialStatsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#FFF",

    },

    likesIcon: {
      color: "#fff"
    },

    commentsIcon: {
      width: "33px",
      height: "30px",
      fill: "#FFF",
    },
    borderShapeLikeL: {
      width: "45.5px",
      height: "96px",
      borderLeft: "1px solid black",
      borderBottom: "1px solid black",
      borderRadius: "0px 0px 0px 18px",
      paddingLeft: "10px",
      marginLeft: "30px"
    },
    countsFonts: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#FFF",
      fontFamily: "Inter",
      fontStyle: "normal"
    },
    everybrandLogoContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "8px",
      fontWeight: 700,
      color: "#FFF",
    },
    everybrandText: {
      paddingTop: "3px",
      fontSize: "8px",
      fontWeight: 700,
      fontFamily: "Inter",
      fontStyle: "normal"
    },
    postOverlayTitle: {
      fontSize: "15px",
      fontWeight: 700,
      color: "#FFF",
      fontFamily: "Inter",
      fontStyle: "normal"
    },
    postOverlayCaption: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Inter",
      fontStyle: "normal",
      color: "#FFF",
    },
    showMoreLink: {
      fontWeight: 700,
      color: "#FFF",
      fontSize: "14px",
      fontFamily: "Inter",
      fontStyle: "normal",
    },
    postSocialStats: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
    },
    avatarContainer: {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      overflow: "hidden",
    },
    avatar: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    postReplyField: {
      borderRadius: "50px",
      background: "#F1F5F9",
      padding: "16px 12px"
    },
    postReplyInput: {
      '&::placeholder': {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "normal",
        color: '#64748B',
      }
    },
    commentsContainer: {
      marginTop: "39px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      "& p": {
        fontStyle: "normal",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700
      },
      "& > button": {
        padding: "0px",
        transition: "transform 0.2s"
      },
      "& > div:first-child": {
        cursor: "pointer",
        padding: "3px 8px",
        borderRadius: "12px",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.04)"
        }
      }
    },
    commentListWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    commentsScrollableWrapper: {
      maxHeight: "50vh",
      overflowY: "auto",
      marginBottom: "50px",
      width: "100%",
    },
    addCommentWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      position: "fixed",
      bottom: "0px",
      padding: "6px 8px 8px ",
      backgroundColor: "#fff",
      zIndex: 4,
      "@media (max-width: 1149px)": {
        bottom: "58px",
      },
    },
    addCommentInnerWrapper: {
      display: "flex",
      alignItems: "flex-end",
      gap: "5px",
      width: "55%",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
    },
    mainNotificationContainer: {
      flexDirection: "column",
      backgroundColor: "#FFF",
      justifyContent: "flex-start",
      display: "flex",
      padding: "20px 30px",
      "@media (max-width: 1149px)": {
        padding: "15px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px 15px",
      },
    },
    postHeadingWrapper: {
      display: "flex",
      gap: "10px",
      alignItems: "center"
    },
    activeUserAvatar: {
      color: "black",
      width: "44px",
      height: "44px",
      border: '2px solid #F0E5FF',
      backgroundColor: '#FFFFFF',
    },
    fallbackAvatar: {
      width: '44px',
      height: '44px',
      objectFit: 'cover'
    },
    postAvatar: {
      display: "flex",
      gap: "13.5px",
      alignItems: "center",
      "& > div:nth-child(1)": {
        marginBottom: "0px"
      }
    },
    name: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px"
    },
    contactInfoNumber: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "22px",
    },
    postWrapper: {
      marginTop: "20px",
      justifyContent: "center",
      display: "flex",
      borderRadius: "8px",
      overflow: "hidden"
    },
    previewContainer: {
      position: "relative",
    },
    imagePreviewContainer: {
      display: "flex",
      minWidth: "281px",
      width: "100%",
      maxWidth: "376px",
      overflow: "hidden",
      background: "#F2F2F2",
      height: "68vh",
      "& > img": {
        objectFit: "cover",
      },
      "& > video": {
        width: "100%",
        objectFit: "cover",
      },
    },
    messageDetailsWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    commentUsername: {
      fontWeight: 600
    },
    commentText: {
      ontSize: "14px",
      fontWeight: 400,
      fontFamily: "Inter",
      fontStyle: "normal",
      color: "#64748B"
    },
    likeButton: {
      alignSelf: "flex-start",
      marginTop: "14px"
    },
    likedImage: {
      width: "24px",
      height: "24px",
      border: "2px solid transparent"
    },
    postLikedImage: {
      color: "#FF0000"
    },
    commentActionsWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      marginLeft: "10px",
    },
    replyButton: {
      color: "#64748B",
      fontSize: "13px",
      fontStyle: "normal",
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "normal",
      border: "none",
      backgroundColor: "transparent"
    },
    replyExpandWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "3px",
      marginTop: "2px",
      "& > p": {
        color: "#64748B",
        fontSize: "11px",
        fontFamily: "Inter",
        fontWeight: 500
      },
      "& > button": {
        padding: "1px",
        transition: "transform 0.2s"
      },
      "& > svg": {
        color: "#64748B",
      }
    },
    expandedArrow: {
      transform: "rotate(180deg)"
    },
    iconButtonWrapper: {
      marginBottom: "3px",
      "& img": {
        height: "42px"
      }
    },
    commentImagePreviewWrapper: {
      position: "relative",
      margin: "5px 10px",
      height: "50px",
      width: "50px",
      "& > button": {
        position: "absolute",
        top: "-9px",
        right: "-8px",
        backgroundColor: "#ffca29",
        padding: "1px",
        "&:hover": {
          backgroundColor: "#ffca29"
        },
        "& svg": {
          fontSize: "1.1rem",
          color: "#000"
        }
      }
    },
    commentImagePreview: {
      height: "100%",
      width: "100%",
      borderRadius: "8px",
      marginTop: "3px",
      cursor: "pointer"
    }
  })
export const OpenNotificationWeb = withStyles(styles)(OpenNotification)
// Customizable Area End
