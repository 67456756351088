// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  stepOne: boolean;
  stepTwo: boolean;
  stepThree: boolean;
  loading: boolean;
  userName: string;
  userPhoneNumber: string;
  phoneNumberError:boolean;
themeSwitch: boolean
}
interface SS {
  id: any;
}
export default class EndUserSignInController extends BlockComponent<
  Props,
  S,
  SS
> {
  endUserSignInApi: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      userName: "",
      userPhoneNumber: "",
      stepOne: true,
      stepTwo: false,
      stepThree: true,
      loading: false,
      phoneNumberError: false,
      themeSwitch: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);
    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.endUserSignInApi) {
        this.setState({ loading: false });
        if (responseJson.data) {
          localStorage.setItem("EndUserAuthToken", responseJson.meta.token);
          this.navigateToOtpScreen();
        } else {
          this.setState({phoneNumberError:true,loading:false})
        }
      }
    }
  }

  handelThemeChange = (e: any) => {
    this.setState({themeSwitch: e.target.checked})
    localStorage.setItem("yellowTheme", e.target.checked)
  }

  handleSignUpClick = () => {
    const messag = new Message(getName(MessageEnum.NavigationMessage));
    messag.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserEmailSignUp");
    messag.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    messag.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messag);
  }

  handleBULoginRedirect = () => {
    const messag = new Message(getName(MessageEnum.NavigationMessage));
    messag.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    messag.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messag);
  }

  navigateToOtpScreen() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserOtpAuth");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const params = {
      phone: this.state.userPhoneNumber,
      name: this.state.userName,
      signUp:true,
    };
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserOtpValidationMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.EndUserOtpValidationMessage),
      params
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  async senOtpToUserSignINFlow(number: any) {
    localStorage.setItem("isUserLogin", "true");
    this.setState({
      loading: true,
      userPhoneNumber: number,
      phoneNumberError:false
    });
    const data = {
      attributes: {
        full_phone_number: `+${number}`,
      },
      type: "sms_account",
    };

    const httpBody = {
      data: data,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.endUserSignInApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endUserLoginEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
