import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import { getStorageData } from "../../../framework/src/Utilities";
import {
  imgBell,
  priorityIcon,
  commentIcon,
  reviewIcon,
  reportPost,
  likeIcon,
  selectedCommentIcon,
  selectedLikeIcon,
  selectedPriorityIcon,
  selectedReportIcon,
  selectedReviewIcon,
} from "./assets";

const filterParamMap: { [key: string]: string } = {
  All: "",
  Comments: "BxBlockComments::Comment",
  Reviews: "BxBlockReviews::Review",
  Like: "BxBlockLike::Like",
  Priority: "priority",
  "Reported Post": "BxBlockReportedPost::ReportedPost",
}
interface BrandAttributesNotification {
  activated: boolean;
  subscription_paused: boolean;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  phone_number: string;
  country_code: string;
  type: string;
  zip_code: string;
  email: string;business_website: string;
  social_phone_number: string;
  follows: {
    followers: string;
    followings: string;
  };
  last_name: string;
  first_name: string;
  sign_up_step: number;
  full_phone_number: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  updated_at: string;
  created_at: string;
  cover_photo: {
    url : string
  } | null;
  full_name: string | null;
}
interface BrandNotifiacation {
  id: string;
  type: string;
  attributes: BrandAttributesNotification;
}

interface IPostRedirectPayload {
  postId: string
  commentId: string
}

interface IFilter {
  name: string
  checked: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  filters: IFilter[];
  setToken: string;
  isLoading: boolean
  notificationData: [];
  isShowAlert: boolean;
  showSuccess: string;
  anchorEl: any;
  notificationId: any;
  commentType: string;
  previewNotificationData: any;
  EuFilters: any[], // added EU states 
  EunotificationData: [],
  euisShowAlert: boolean,
  eushowSuccess: string;
  brandsIFollowNotifiacation: BrandNotifiacation[];
  suggestedBrandsNotifiacation: BrandNotifiacation[];
  top6: BrandNotifiacation[]
  isGettingCommentDetails: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationCallID: string = "";
  filterApiCallId: string = "";
  markAllAsReadCallId: string = "";
  getMarkAsPriorityCallID: string = "";
  pinToTopId: string = "";
  updateAsReadCallId: string = "";
  openNotificationID: any = "";
  getEuNotificationCallID: string = "";
  eumarkAllAsReadCallId: string = "";
  euNotificationBrandsApiCallId: string ="";
  followBrandNotificationApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      filters: [
        { name: "All", checked: true },
        { name: "Comments", checked: false },
        { name: "Reviews", checked: false },
        { name: "Like", checked: false },
        { name: "Priority", checked: false },
        { name: "Reported Post", checked: false },
      ],
      setToken: "",
      isLoading: false,
      notificationData: [],
      isShowAlert: false,
      showSuccess: "",
      anchorEl: null,
      notificationId: 0,
      commentType: "",
      previewNotificationData: {},
      EuFilters: [    //added new data for testing
        {
          name: "All",
          checked: true,
          block: " "
        },
        {
          name: "Comments",
          checked: true,
          block: "BxBlockComments::Comment"
        },
        {
          name: "Like",
          checked: true,
          block: "BxBlockLike::Like"
        },
        {
          name: "Followers",
          checked: true,
          block: "BxBlockFollowers::UserFollow"
        },
        {
          name: "Mark all as read",
          checked: true
        }
      ],
      EunotificationData: [],
      euisShowAlert: false,
      eushowSuccess: "",
      brandsIFollowNotifiacation: [],
      suggestedBrandsNotifiacation: [],
      top6: [],
      isGettingCommentDetails: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.notificationApiCall(" ");
    this.EUnotificationApiCall(" ");
    this.getSuggestedAndFollowBrandNotification();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }
    const messageType = getName(MessageEnum.RestAPIResponceMessage);
    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleResponseblock(responseJson,apiRequestCallId)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  renderFilterIcon = (filterName: string, isChecked: boolean) => {
    const { classes } = this.props

    const iconMap = {
      Comments: { defaultIcon: commentIcon, selectedIcon: selectedCommentIcon, sizeClass: classes.commentIcon },
      Reviews: { defaultIcon: reviewIcon, selectedIcon: selectedReviewIcon, sizeClass: classes.icon },
      Like: { defaultIcon: likeIcon, selectedIcon: selectedLikeIcon, sizeClass: classes.icon },
      Priority: { defaultIcon: priorityIcon, selectedIcon: selectedPriorityIcon, sizeClass: classes.priorityIconFilter },
      "Reported Post": { defaultIcon: reportPost, selectedIcon: selectedReportIcon, sizeClass: classes.priorityIconFilter },
    };
  
    const iconData = iconMap[filterName as keyof typeof iconMap];
  
    return iconData ? (
      <img
        src={isChecked ? iconData.selectedIcon : iconData.defaultIcon}
        alt={`${filterName}Icon`}
        className={iconData.sizeClass}
      />
    ) : null;
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markasReadEndpoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  renderProfileImage(value: any) {
    if (value.attributes.created_by.data) {
      if (value.attributes.created_by.data.attributes.profile_pic) {
        return true;
      } else {
        return false;
      }
    }else {
      return false
    }
  }

  timeSince(date:string) {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
  
    if (seconds >= 31536000) {
      const years = Math.floor(seconds / 31536000);
      return years === 1 ? `${years} year` : `${years} years`;
    }
    if (seconds >= 2592000) {
      const months = Math.floor(seconds / 2592000);
      return months === 1 ? `${months} month` : `${months} months`;
    }
    if (seconds >= 86400) {
      const days = Math.floor(seconds / 86400);
      return days === 1 ? `${days} day` : `${days} days`;
    }
    if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600);
      return hours === 1 ? `${hours} h` : `${hours} hour`;
    }
    if (seconds >= 60) {
      const minutes = Math.floor(seconds / 60);
      return minutes === 1 ? `${minutes} min` : `${minutes} mins`;
    }
    return `${seconds} sec`;
  }

  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  };

  getPinUnpinText = () => {
    const { notificationId } = this.state
    if(notificationId) {
      const { attributes: { pin_top } } = notificationId
      return pin_top ? "Unpin from the top" : "Pin to the top"
    }
  }

  getPriorityText = () => {
    const { notificationId } = this.state
    if(notificationId) {
      const { attributes: { is_priority } } = notificationId
      return is_priority ? "Unmark as priority" : "Mark as priority"
    }
  }

  checkFilter = (filterName: string) => {
    // Update state to reflect the selected filter
    this.setState(
      (prevState: any) => {
        const updatedFilters = prevState.filters.map((filter: any) => ({
          ...filter,
          checked: filter.name === filterName, // Set checked to true only for the selected filter
        }));

        return { filters: updatedFilters };
      },
      () => {
        const selectedFilter = this.getSelectedFilter()
        if (selectedFilter) {
          this.notificationApiCall(selectedFilter);
        }
      }
    );
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, notificationData: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
    this.openNotification(notificationData.attributes.id);
    this.setState({
      notificationId: notificationData,
      commentType: notificationData.attributes.headings,
    });
  };

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getSelectedFilter = () => {
    const selectedFilter = this.state.filters.find(
      (filter: IFilter) => filter.checked
    )
    return selectedFilter ? selectedFilter.name : ""
  }

  handleNotificationClick = (postId: string) => {
    const payload = {
      postId,
      commentId: ""
    }
    postId && this.redirectToPostPage(payload)
  }

  handleNotificationReplyClick = () => {
    const { notificationId, previewNotificationData } = this.state
    const {
      attributes: {
        headings,
        post_id
      }
    } = notificationId
    let payload = {
      postId: post_id,
      commentId: ""
    }
    const isReply = headings === "BxBlockComments::Comment"
    if(isReply && "comment" in previewNotificationData) {
      const { comment } = previewNotificationData
      payload = { ...payload, commentId: comment.data.id }
    }
    this.redirectToPostPage(payload)
  }

  redirectToPostPage = (payload: IPostRedirectPayload) => {
    localStorage.removeItem(configJSON.SinglePostPayloadKey)
    const msg = new Message(getName(MessageEnum.NavigationMessage))
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "OpenNotification")
    msg.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    const payloadMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage))
    payloadMsg.addData(getName(MessageEnum.NotificationToPostPayloadMessage), payload)
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMsg)
    this.send(msg)
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  markAllAsReadApiCall() {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAllAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.markasReadEndpoint
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  notificationApiCall(filter: string) {
    const filterName = filterParamMap[filter.trim() || this.getSelectedFilter()]
    this.setState({ isLoading: true })
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getNotificationCallID = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}?filter=${filterName}`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  openNotification(id: number) {
    this.setState({ isGettingCommentDetails: true })
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openNotificationID = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_notifications/notifications/${id}/cmnt_reply`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }
  markedAsReadResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        { isShowAlert: true, showSuccess: responseJson.message },
        () => {
          this.isShowCell();
          this.notificationApiCall(" ");
        }
      );
    }
  };

  markAsPriorityResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        { isShowAlert: true, showSuccess: responseJson.message },
        () => {
          this.isShowCell();
          this.notificationApiCall(" ");
          this.handleClose();
        }
      );
    }
  };

  handleRedirections = (data:any) => {
    console.log(data)
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.DashboardRedirection)
    );
    raiseMessage.addData(
      getName(MessageEnum.DashboardRedirection),
      data
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  pinToTopApiResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        { isShowAlert: true, showSuccess: responseJson.message },
        () => {
          this.isShowCell();
          this.notificationApiCall(" ");
          this.handleClose();
        }
      );
    }
  };
  markPerticularNotificationResponse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        { isShowAlert: true, showSuccess: responseJson.message },
        () => {
          this.isShowCell();
          this.notificationApiCall(" ");
          this.handleClose();
        }
      );
    }
  };
  deleteNotificationRespnse = (responseJson: any) => {
    if (responseJson) {
      this.setState(
        { isShowAlert: true, showSuccess: responseJson.message },
        () => {
          this.isShowCell();
          this.handleClose();
          this.notificationApiCall(" ");
        }
      );
    }
  };
  commonFunctionForCondition = (condition: any, param1: any, param2: any) => {
    return condition ? param1 : param2;
  };
  markAsPriorityCall(id: number) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMarkAsPriorityCallID = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}/${id}/update_as_priority`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  pinToTopApiCall(id: number) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.pinToTopId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}/${id}/update_as_pintop`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }
  markAsReadByPerticularNotifictionApiCall(id: number) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateAsReadCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}/${id}/update_mark_as_read`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem(configJSON.AUTH_TOKEN),
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  isNotificationAlreadyRead = () => {
    let isRead = false
    const { notificationId } = this.state
    if(notificationId) {
      const { attributes: { is_read } } = notificationId
      isRead = !!is_read
    }
    return isRead
  }

  EUnotificationApiCall(filterName: string) {

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getEuNotificationCallID = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}?filter=${filterName}`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("EUAuthToken"),
      })
    );

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }
  EUmarkAllAsReadApiCall() {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.eumarkAllAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.markasReadEndpoint
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("EUAuthToken"),
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  GetcolorByIndex(index: number) {
    return index % 2 === 0 ? "#FFFFFF" : "#F7F8FC"
  }
  EunotificationResponse(responseJson: any) {
    if (responseJson) {
      this.setState({ euisShowAlert: true, eushowSuccess: responseJson.message },
        () => {
          setTimeout(() => this.setState({ euisShowAlert: false }), 3000);
        })
    }

  }
  handleResponseblock(responseJson:any,apiRequestCallId:any) {
    switch (apiRequestCallId) {
      case this.getNotificationCallID:
        if (responseJson.data) {
          this.setState({ notificationData: responseJson.data, isLoading: false });
        }
        break;
      case this.markAsReadCallId:
        this.getNotifications();
        break;
      case this.getDataCallId:
        if (responseJson.data) {
          this.setState({
            data: responseJson.data,
          });
        }
        break;
      case this.markAllAsReadCallId:
        this.markedAsReadResponse(responseJson);
        break;
      case this.getMarkAsPriorityCallID:
        this.markAsPriorityResponse(responseJson);
        break;
      case this.pinToTopId:
        this.pinToTopApiResponse(responseJson);
        break;
      case this.updateAsReadCallId:
        this.markPerticularNotificationResponse(responseJson);
        break;
      case this.deleteCallId:
        this.deleteNotificationRespnse(responseJson);
        break;
      case this.openNotificationID:
        if (responseJson) {
          this.setState({ previewNotificationData: responseJson, isGettingCommentDetails: false });
        }
        break;
      case this.getEuNotificationCallID:
        if (responseJson) {
          this.setState({ EunotificationData: responseJson.data });
        }
        break;
      case this.eumarkAllAsReadCallId:
        this.EunotificationResponse(responseJson);
        break;
      case this.euNotificationBrandsApiCallId:
        if(!responseJson.errors) {
        this.setState({brandsIFollowNotifiacation: responseJson.followings.data, suggestedBrandsNotifiacation: responseJson.suggested_brands.data , top6: responseJson.top6.data})
        }
        break;
      case this.followBrandNotificationApiCallId:
        if(responseJson.data) {
          this.getSuggestedAndFollowBrandNotification()
        }
        break;
    }
  }

  getSuggestedAndFollowBrandNotification = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euNotificationBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUNotificationBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUNotificationBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUNotificationBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  followBrandNotificationApi = async (brandId: string) => {
    const token = await getStorageData(configJSON.EU_AUTH_TOKEN)

    const httpBody = {
      data:{
          attributes:{
              followed_user_id: brandId
          }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followBrandNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followBrandApiEndpoint
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
   )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
