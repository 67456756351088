// Customizable Area Start
import React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, TextField } from "@material-ui/core";

type Props = {
  invitesContacts: string[];
  sendInvites: () => void;
  closeModal: () => void;
  setInvitesContacts: (invites: any, i: any, field: any) => void;
} & WithStyles<typeof styles>;

const AfterSignupInvites = ({
  invitesContacts,
  sendInvites,
  closeModal,
  setInvitesContacts,
  classes
}: Props) => {

  const handleSendInvites = () => {
    sendInvites();
  };
  
  const inputs = Array.from({ length: 4 }, (_, i) => (
    <Box key={i} display="flex" alignItems="center">
      {['firstName', 'lastName', 'email'].map((field, idx) => (
        <TextField
          key={field}
          id={`inviteInput-${i}-${field}`}
          variant="outlined"
          className={classes.inviteInput}
          value={invitesContacts[i * 3 + idx] || ""}
          fullWidth
          placeholder={`Employee #${i + 1}'s ${field.charAt(0).toUpperCase() + field.slice(1)}`}
          onChange={(event) => setInvitesContacts(event, i, field as 'firstName' | 'lastName' | 'email')}
        />
      ))}
    </Box>
  ));

  return (
    <Box className={classes.container} id="policy">
      <Typography className={classes.title}>
        Teamwork makes your customer's dreams work
      </Typography>
      <Typography className={classes.description}>
        Invite your team! Let the members of your team take charge of customer communication, whether it's reaching out to new clients or keeping the conversation going with existing ones. They'll join as Page Admins, giving them the tools to interact effortlessly with customers on your Everybrand platform.
      </Typography>
      <Typography className={classes.subtitle}>Your team</Typography>
      <Box className={classes.invitesContainer}>
        {inputs}
      </Box>
      <Box className={classes.controlsContainer}>
        <span id="skipInviteButton" onClick={closeModal} className={classes.skipButton}>
          Skip
        </span>
        <Button
          id="sendInviteButton"
          className={classes.inviteButton}
          onClick={handleSendInvites}
        >
          Invite
        </Button>
      </Box>
    </Box>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      color: "#0F172A",
      overflowY: "auto",
    },
    title: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    subtitle: {
      fontSize: "16px",
      fontWeight: 700,
      marginTop: 24,
    },
    description: {
      fontSize: "16px",
      fontWeight: 700,
      margin: "16px 0",
    },
    invitesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "17px",
      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },
      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },
    inviteInput: {
      height: "40px",
      display: 'flex',
      width: "90%",
      alignItems: 'center',
      "& *": {
        fontSize: "16px",
        fontWeight: 700,
      },
      "& > *": {
        height: "100%",
        borderRadius: "20px",
        width: "90%",
      },
    },
    controlsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "24px",
    },
    skipButton: {
      fontSize: "16px",
      fontWeight: 700,
      cursor: "pointer",
      marginRight: "64px",
    },
    inviteButton: {
      alignSelf: "flex-end",
      width: "253px",
      height: "44px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
    },
  });

export default withStyles(styles)(AfterSignupInvites);


// Customizable Area End
