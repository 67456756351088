Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/dashboards";
exports.followersBackGetUrl = "/bx_block_followers/follows/follower_list";
exports.websitesGetUrl = "/account_block/websites";
exports.accountApiEndpoint = "/account_block/accounts"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

//Invite friends
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = 'PUT';
exports.httpDelMethod = 'DELETE'
exports.EU_AUTH_TOKEN = "EUAuthToken"
exports.AUTH_TOKEN = "authToken"

exports.inviteFriendApiContentType = 'application/json';

exports.inviteFriendEndPoint = 'bx_block_teammates/invites/invite_friend';
exports.bulkInviteFriendEndPoint = 'bx_block_teammates/invites/bulk_invite';
exports.contactsEndPoint = 'account_block/contacts';
exports.getDashboardData = 'bx_block_dashboard/home_end_user';
exports.addLikeToPostEndPoint = 'bx_block_like/likes';
exports.createFollowerEndPoint = 'bx_block_followers/follows/create_follower';

exports.labelInviteFriendToEverybrand = 'Invite Friend to Everybrand';

exports.inviteFriendDescriptionText = "Help your business friends grow their companies by inviting them to join Everybrand.";
exports.bulkInviteProposal = "Send to multiple contacts at once "
exports.bulkInviteLink = "Try a bulk invite."
exports.addContactQuestion = "Is your contact not listed? "
exports.addContactLink = "Add it here"
exports.sendTo = "Send to"
exports.sendInvite = "Send Invite"
exports.templatePreview = "Template Preview"
exports.tryOtherAnswers = "Try other answers"
exports.automationsTemplates = "Automations templates"
exports.autolink = "Autolink"
exports.addContactModalTitle = "Add Contact"
exports.createContactTitle = "Create Contact"
exports.createContactDescription = "Don't see contact in the list? Create one here"
exports.firstName = "First Name"
exports.lastName = "Last Name"
exports.email = "Email"
exports.mobileNumber = "Mobile number"
exports.addContactButton = "ADD CONTACT"
exports.back = "Back"
exports.template = "Hi, [Contact]. Thank you for choosing [Business Name]. Could you take 30 seconds to leave us a review using the following [Link]"

exports.currentContacts = "Current Contacts"
exports.contactList = "Contact list"
exports.search = "Search"
exports.createContactDescription = "Don\'t see contact in the list? Create one here"
exports.selectedUsers = "SelectedUsers"
exports.sendBulkInvite = "Send bulk invite"

// Contacts list
exports.bannedContactsEndpoint = "account_block/contacts/banned_users"
exports.optOutContactEndpoint = "account_block/contacts/opt_out_users"

exports.confirm = "Confirm";
exports.privacyPolicyTitle = "Privacy Policy and Terms of Service"
exports.privacyPolicyContentText = "Suspendisse at lobortis purus. Morbi hendrerit imperdiet hendrerit. Aliquam dictum euismod tristique. Aenean viverra placerat metus, at efficitur eros. Vivamus condimentum sed orci vitae venenatis. Curabitur fermentum tellus et bibendum cursus. Ut porttitor dui ultrices, dignissim urna id, pretium metus. Etiam dictum dui a sem ornare, id iaculis justo hendrerit. In id tellus viverra, ultricies libero in, mollis arcu. Suspendisse at lobortis purus. Morbi hendrerit imperdiet hendrerit. Aliquam dictum euismod tristique. Aenean viverra placerat metus, at efficitur eros. Vivamus condimentum sed orci vitae venenatis. Curabitur fermentum tellus et bibendum cursus. Ut porttitor dui ultrices, dignissim urna id, pretium metus. Etiam dictum dui a sem ornare, id iaculis justo hendrerit. In id tellus viverra, ultricies libero in, mollis arcu. Suspendisse at lobortis purus. Morbi hendrerit imperdiet hendrerit. Aliquam dictum euismod tristique. Aenean viverra placerat metus, at efficitur eros. Vivamus condimentum sed orci vitae venenatis. Curabitur fermentum tellus et bibendum cursus. Ut porttitor dui ultrices, dignissim urna id, pretium metus. Etiam dictum dui a sem ornare, id iaculis justo hendrerit. In id tellus viverra, ultricies libero in, mollis arcu. Suspendisse at lobortis purus. Morbi hendrerit imperdiet hendrerit. Aliquam dictum euismod tristique. Aenean viverra placerat metus, at efficitur eros. Vivamus condimentum sed orci vitae venenatis. Curabitur fermentum tellus et bibendum cursus. Ut porttitor dui ultrices, dignissim urna id, pretium metus. Etiam dictum dui a sem ornare, id iaculis justo hendrerit. In id tellus viverra, ultricies libero in, mollis arcu."
exports.viewProfile = "View Profile"
exports.complianceInfo = "Compliance Info"
exports.editUser = "Edit User"
exports.banUser = "Ban user from platform"
exports.unbanUser = "Unban User"
exports.approvePhotoAndVideo = "Approve Photo & Video posts"
exports.allContacts = "All Contacts"

// After signup
exports.firstLoginInviteEndpoint = "bx_block_teammates/invites/first_login_invite"
exports.privacyPolicyEnpoint = "bx_block_privacy_settings/privacy_policies"
exports.updateProfilePicEnpoint = "account_block/accounts/update_profile_pic"
exports.updateFirstLoginStatusEndpoint = "account_block/accounts/update_login_status"
exports.welcomeMessageBold = "And just like that, you and your business have become the platform. This is a big moment in your business's story. You can now create more conversations, build a community of raving fans, and increase your new customers all from the most important place on the internet, your website."
exports.welcomeMessageSimple_1 = "One of our Everybrand specialists will be contacting you shortly to discuss your specific needs and assist you in getting started."
exports.welcomeMessageSimple_2 = "Before that happens, feel free to browse your new page, receive your free Everybrand social texting number, and invite you\'re team to your new page."
exports.welcomeMessageSimple_3 = "We're excited to be a part of all the amazing things your going to do."
exports.welcomeMessageSimple_4 = "Welcome to Everybrand!"
exports.sign = "- Andrew Clark, Founder & CEO"

exports.numberText_1 = "Here is your free Everybrand number. You can use it to send and receive texts from your customers via Everybrand."
exports.numberText_2 = "Even better, this new number comes with some pretty cool automations that allow you to engage with your customers through web chat, engagement notifications, review requests, and so much more."
exports.numberTextBold = "Use this number in your location, on promotional items, or anywhere you'd like."
exports.hidePostEndPoint = "/bx_block_posts/hides"
exports.postCommentEndPoint="/bx_block_comments/comments"
exports.archiveCommentEndPoint="/bx_block_dashboard/archives"
exports.likeAPIEndPoint="/bx_block_like/likes"

// Navigation menu
exports.menuPostsDescription = "Build connection & brand loyality"
exports.menuEveryreelDescription = "Create automated tailored experiences that generate leads"
exports.menuMessageDescription = "Get the conversation started"
exports.menuContactDescription = "Add new contact to your list"
exports.menuReviewDescription = "Increase credibility by sending a review invite"

//Analytics endpoints
exports.overviewAnalyticsEnpoint = "bx_block_analytics/dashboards"
exports.leadsAnalyticsEnpoint = "bx_block_analytics/leads_analytics"
exports.waitlistAnalyticsEnpoint = "bx_block_analytics/waitlist_analytics"
exports.reviewAnalyticsEndpoint = "bx_block_analytics/review_analytics"
exports.everyreelAnalyticsEndpoint = "bx_block_analytics/everyreel_analytics"
exports.engagementAnalyticsEnpoint = "bx_block_analytics/platform_engagements"
exports.engagementTrendingPostsEndpoint = "bx_block_analytics/platform_engagements/trending_posts"
exports.engagementEverybrandPollsEndpoint = "bx_block_analytics/platform_engagements/everybrand_polls"
exports.engagementPostFeedClicksEndpoint = "bx_block_analytics/platform_engagements/post_feed_clicks"
exports.engagementTextConversationsEndpoint = "bx_block_analytics/platform_engagements/text_conversations"
exports.engagementConnectedPlatformsEndpoint = "bx_block_analytics/platform_engagements/connected_platforms"

// Suggested Brands
exports.suggestedBrandsEndpoint = "bx_block_dashboard/home_end_user"
exports.followBrandApiEndpoint = "bx_block_followers/follows/create_follower"

//Eu trending 

exports.euTrendingpostEndpoint = '/bx_block_posts/posts/trending';
exports.euTrendingPostMethod ="GET"

exports.noDataAvailableText = "No data available"
exports.POST_PER_PAGE = "10"
exports.endPointApiGetEUTrendingBrands = "bx_block_dashboard/dashboards/end_user_data";
exports.undoLikeApiEndpoint = "bx_block_like/likes/undo_like"
exports.getPostsApiEndpoint = "bx_block_dashboard/dashboards/all_post"
exports.contentTypeApiGetEUTrendingBrands = "application/json";
exports.methodTypeApiGetEUTrendingBrands = "GET";
exports.successfullyHiddenPostMessage = "Post Got Hidden Successfully!"
exports.PostClass = "BxBlockPosts::Post"
exports.CommentClass = "BxBlockComments::Comment"

exports.likeableTypes = {
  "Post": "BxBlockPosts::Post",
  "Comment": "BxBlockComments::Comment"
}
// Customizable Area End