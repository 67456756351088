import React from "react";
import { Box, Button, Grid, Typography, Theme, createStyles, withStyles } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Rating from "@material-ui/lab/Rating";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import UserProfileController from "./UserProfileController.web";
import { Swiper, SwiperSlide } from 'swiper/react';




const mostActive = [
    {
        name: "Paquita"
    },
    {
        name: "Michelle Jones"
    },
    {
        name: "Jackson Bates"
    },
    {
        name: "Andrew Jackson"
    },
    {
        name: "Anna Smith"
    },
    {
        name: "Jhonatan reed"
    }
]

const Quotes = [
    {
        quoteHeading: "My real-life super power is...",
        quoteDescription: "I can fall asleep in 5 minutes. Any where. Any time."
    },
    {
        quoteHeading: "My real-life super power is...",
        quoteDescription: "I can fall asleep in 5 minutes. Any where. Any time."
    },
    {
        quoteHeading: "My real-life super power is...",
        quoteDescription: "I can fall asleep in 5 minutes. Any where. Any time."
    },
    {
        quoteHeading: "My real-life super power is...",
        quoteDescription: "I can fall asleep in 5 minutes. Any where. Any time."
    }
]
export class UserProfileBasicBu extends UserProfileController {
    renderFollowingButton() {
        return (
            this.state.userID && this.state.selfProfile === "false" && <Button style={webStyle.contactUsbtn} data-test-id="follow-btn-bu" onClick={()=>this.followBrandApiProfile(this.state.userID, false, this.state.isFollowing)}>{this.state.isFollowing ? "Following" : "Follow"}</Button>
        )
    }

    followerData() {
        return (
            <Box style={webStyle.swiperContainer}>
                <div style={{ ...webStyle.swiperButton, left: "-41px" }}
                    id="swiper-button-prev">
                    <ArrowBackIosOutlinedIcon style={{ width: "33px", height: "24px", color: "#334155" }} />
                </div>
                <Swiper
                    spaceBetween={4}
                    slidesPerView={this.state.followersData.followers_count <= 5 ? 5 : 10}
                    navigation={{
                        prevEl: '#swiper-button-prev',
                        nextEl: '#swiper-button-next',
                    }}
                >
                    {
                        this.state.followersData && this.state.followersData?.followers?.data?.map((followers: any, index: any) => (
                            <SwiperSlide key={index} >
                                <Box style={webStyle.brandsLiContainer}>
                                    <Box style={webStyle.followerImageContainer}>
                                        <img src={followers.attributes.profile_pic} alt={followers.name} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                                    </Box>
                                    <Typography style={webStyle.followersName}>{followers.attributes.full_name ? followers.attributes.full_name : `${followers.attributes.first_name} ${followers.attributes.last_name}`}</Typography>
                                </Box>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div style={{ ...webStyle.swiperButton, right: "-50px" }} id="swiper-button-next">
                    <ArrowForwardIosOutlinedIcon style={{ width: "33px", height: "24px", color: "#334155" }} />
                </div>
            </Box>
        )
    }
    render() {
        const { profile_reviews,
            followers_count,
            data } = this.state.userDetails;
            const { classes }: any = this.props;
        return (
            <>
                <Box style={webStyle.followersContainer}>
                    <Typography style={webStyle.followersHeading}>
                        Followers
                    </Typography>
                    {
                        followers_count &&
                        this.followerData()
                        ||
                        <Typography style={webStyle.followersHeading}>
                            No followers found
                        </Typography>

                    }

                </Box>
                <div style={webStyle.root}>
                    <Box style={{
                        backgroundImage: data && `url(${data.profile.data.attributes.photo})`
                    }}
                    className={classes.profileImage}
                    >
                        <Box style={{ textAlign: "end", padding: "10px 16px 10px 16px" }}>
                            {!this.state.userID && <Link to="/UserProfile">
                                <IconButton
                                    aria-label="edit profile picture"
                                    style={{ width: "31px", height: "31px", backgroundColor: "#0F172A", borderRadius: "8px" }}
                                >
                                    <EditOutlinedIcon style={{ color: "#FFFFFF" }} />
                                </IconButton>
                            </Link>}
                        </Box>

                        <Box style={webStyle.contentWrapper}>
                            <Box style={{display: "flex", flexDirection: 'column', gap: "3px"}}>
                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    color: "#FFFFFF",
                                    marginBottom: "5px"
                                }}>
                                    {data?.profile.data.attributes.city}{" "}{data?.profile.data.attributes.country}
                                </Typography>
                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: "30px",
                                    fontWeight: 700,
                                    color: "#FFFFFF",
                                    lineHeight: "24px",
                                    marginBottom: "5px"
                                }}>
                                    {data?.profile.data.attributes.name}
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: 400,
                                        color: "#FFFFFF"
                                    }}>
                                    {data && data.profile.data.attributes.headline}
                                </Typography>
                                {this.renderFollowingButton()}
                            </Box>
                            <Box data-test-id="followWrapper" style={{ display: "flex", gap: "44px" }}>
                                <Box>
                                    <Typography style={webStyle.followFollowingCount}>
                                        {data && data.profile.data.attributes.followers}
                                    </Typography>
                                    <Typography style={webStyle.followFollowing}>Followers</Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.followFollowingCount}>
                                        {data && data.profile.data.attributes.following}
                                    </Typography>
                                    <Typography style={webStyle.followFollowing}>Following</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <div style={webStyle.infoContainer}>
                        <Box style={webStyle.quoteContainer}>
                            <Swiper
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                style={{height: "120px"}}
                            >
                                {Quotes && Quotes.map((quote, index) => (
                                    <SwiperSlide key={index}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                            {index > 0 && (
                                                <Box >
                                                    <ArrowBackIosOutlinedIcon data-test-id={`prev-button-${index + 1}`} className="swiper-button-prev" style={webStyle.quotesButton} />
                                                </Box>
                                            )}

                                            <Box>
                                                <Typography style={webStyle.quoteHeading}>
                                                    {quote.quoteHeading}
                                                </Typography>
                                                <Typography style={webStyle.quoteDescription}>
                                                    {quote.quoteDescription}
                                                </Typography>
                                            </Box>


                                            {index < Quotes.length - 1 && (
                                                <Box>
                                                    <ArrowForwardIosOutlinedIcon data-test-id={`next-button-${index + 1}`} className="swiper-button-next" style={webStyle.quotesButton} />
                                                </Box>
                                            )}
                                        </Box>
                                    </SwiperSlide>
                                ))}
                                <Box>
                  </Box>
                            </Swiper>
                        </Box>
                        <Box data-test-id="slider-bu-dots-container">
                        <ul style={webStyle.slidesDotsUl}>
                    {[1,2,3].map((presonalityPompt, index) => (
                    <li 
                    style={{fontSize: "28px", color: this.state.currentSlide === index ? "#FFC629" : ""}}
                    >
                    </li>))}
                    </ul>
                    </Box>
                        <Box style={webStyle.ratingContainer}>
                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "40px",
                                fontWeight: 700,
                                lineHeight: "24px",
                                color: "#000000"
                            }}>
                                {data && data.profile.data.attributes.reviews}{".0"}
                            </Typography>
                            <Rating
                                name="size-large"
                                size='large'
                                value={data && data.profile.data.attributes.average_rating}
                                readOnly
                                precision={0.1}
                                style={{color: "#FFC629", fontSize: "60px" }}
                            />
                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: 700,
                                lineHeight: "24px",
                                color: "#000000"
                            }}>
                                {data && data.profile.data.attributes.reviews} Reviews
                            </Typography>
                        </Box>
                        {!this.state.userID && <Box style={webStyle.top6Container}>
                            <Typography style={webStyle.top6heading}>Your Top 6</Typography>
                            <Typography
                                style={webStyle.activeUserHeading}>Most active users in platform</Typography>
                            <Grid container spacing={2} style={{ marginTop: "44px", paddingLeft: "42px" }}>
                                {
                                    mostActive && mostActive.map((active, index) => (
                                        <>
                                            <Grid md={4} >
                                                <img src={`https://avatar.iran.liara.run/public/${index+1}`} alt=""
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        borderRadius: "136px",
                                                        border: "2px solid #FFC629"
                                                    }} />
                                                <Typography style={webStyle.activeName}>{active.name}</Typography>
                                            </Grid>
                                        </>
                                    ))
                                }

                            </Grid>
                        </Box>}
                        <Box style={webStyle.postContainer}>
                            <Typography style={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                color: "#0F172A",
                                paddingBottom: "10px"

                            }}>Posts</Typography>
                            <Grid container spacing={2}>
                                {data && data.posts.data.map((post: any, index: any) => (
                                    <Grid item key={index} xs={4} style={{flexBasis: "0%"}}>
                                        <Box
                                            sx={{
                                                width: '196px',
                                                height: '328px',
                                                position: "relative",
                                            }}
                                        >
                                            {post.attributes.files && post.attributes.files[0] ? (
                                                this.isVideo(post.attributes.files[0]) && (
                                                    <video
                                                        src={post.attributes.files[0].link}
                                                        width="100%"
                                                        height="100%"
                                                        controls
                                                    />
                                                ) || (
                                                    <img
                                                        src={post.attributes.files[0].link}
                                                        alt=""
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                )
                                            ) : (
                                                <img
                                                        src={`https://picsum.photos/id/${index + 10}/200/300`}
                                                        alt=""
                                                        height="100%"
                                                        width="100%"
                                                    />
                                            )}

                                            {/* <img src={post.attributes.account_profile.url} height="100%" width="100%" alt="" /> */}
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    bottom: 8,
                                                    left: 8,
                                                    color: '#fff',
                                                    padding: '2px 8px',
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    &#9654; 210
                                                </Typography>
                                            </Box>
                                            <Button style={webStyle.trendingBtn}>Trending</Button>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>

                        </Box>

                    </div>
                </div >
            </>
        )
    }
}

const webStyle = {
    followersContainer: {
        width: "60%",
        margin: "20px",
        maxHeight: "200px",
    },
    followersHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        paddingLeft: "67px",
        marginBottom: "27px",
    },

    followersName: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "13px",
        color: "#334155",
        textAlign: "center",
        marginTop: "10px"
    } as React.CSSProperties,
    top6heading: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        marginBottom: "8px"
    },
    activeUserHeading: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000"
    },
    activeName: {
        margin: "18px 0",
        // textAlign: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "13px",
        color: "#334155"
    } as React.CSSProperties,
    root: {
        backgroundColor: "#FFFFFF",
        maxWidth: "780",
        margin: "20px 0 0 77px",
        width: "100%",
    },
    quoteContainer: {
        maxHeight: "85px",
    },
    quoteHeading: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFC629",
        padding: "14px 0 13px 35px",
    },
    quotesButton: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        position: "static",
        color: "#334155",
        border: "2px solid #334155",
        borderRadius: "50%",
        padding: "5px"
    } as React.CSSProperties,
    quoteDescription: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        paddingLeft: "35px"
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "30px 40px",
        position: "relative"
    } as React.CSSProperties,
    contactUsbtn: {
        background: "#FFC629",
        padding: "10px 16px 10px 16px",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        borderRadius: "30px",
        textTransform: "none",
        cursor: "pointer",
        maxWidth: "150px"
    } as React.CSSProperties,
    followFollowing: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#FFFFFF"
    },
    followFollowingCount: {
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        color: "#FFFFFF",
        textAlign: "center"
    } as React.CSSProperties,
    infoContainer: {
        padding: "14px 16px 31px 35px",
    },
    ratingContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "24px 0",
        borderTop: "2px ridge #CBD5E1",
        paddingTop: "30px"
    },
    top6Container: {

    },
    postContainer: {
        marginTop: "65px",
    },
    trendingBtn: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#000000",
        position: "absolute",
        top: "5px",
        left: "108px",
        textTransform: "none",
        borderRadius: "29px",
        backgroundColor: "#FFC629"
    } as React.CSSProperties,
    swiperButton: {
        position: "absolute",
        transform: "translateY(-50%)",
        // zIndex: 10,
        borderRadius: "50%",
        width: "37px",
        height: "37px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "2px solid #334155",
        top: 54
    } as React.CSSProperties,
    swiperContainer: {
        position: "relative",
        marginLeft: "57px",
        "& .swiper-wrapper": {
            height: "auto"
        }
    } as React.CSSProperties,
    brandsLiContainer: {
        margin: "15px 30px",
        width: "100%"
    } as React.CSSProperties,
    followerImageContainer: {
        width: "82px",
        height: "85px",
        border: "2px solid #FFC629",
        borderRadius: "50%",
    },
    slidesDotsUl: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px",
        gap:"30px",
        "& li": {
          margin: "0px 15px",
          fontSize: "32px",
        },
      },
      slidesDotsColor: {
        color: "#FFC629 !important"
      },
    swiperSlideContainer: {
        height: "auto"
    }
}

const styles = (theme: Theme) => createStyles({
    profileImage: {
        // backgroundImage: `url(${Woman})`,
        borderRadius: "20px 20px 0px 0px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "451px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        "&::before": {
            content: '""',
            display: 'block',
            background: "linear-gradient(0deg, rgba(3,2,11,0.8127626050420168) 0%, rgba(8,12,13,0) 52%)",
            position:'absolute',
            top:0,
            left:0,
            right:0,
            bottom:0
          }
    },
  });

export default withStyles(styles)(UserProfileBasicBu);